import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddReleaseNoteModal from './AddReleaseNoteModal';
import { wordpressBaseUrl } from 'settings';
import Axios from 'axios';
import { months } from 'moment';

const MonthsIndex = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December"
};

const Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
];

const Accordion = withStyles({
  root: {
    borderBottom: "1px solid #333333",
    background: 'transparent',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

function RowActionMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const open = Boolean(anchorEl);
  const {note, setEditNote} = props;

  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  const editNote = () => {
    setEditNote(note);
    console.log('note', note)
  }

  const deleteNote = async() => {
    console.log('note', note)
    if (window.confirm('Are you sure!')) {
      const url = `${wordpressBaseUrl}/wp-json/hh-api/admin/delete-release-note`;
      const input = {id: note.id};
      const reponse = await Axios.post(url, input);
      console.log("update team response", reponse.data);
      window.location.reload()
    }
  }

  return (
      <div>
          <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
          >
              <MoreVertIcon />
          </IconButton>
          <Menu
              id="long-menu"
              MenuListProps={{
                  'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                  style: {
                      width: '22ch',
                  },
              }}
          >
              <MenuItem key={2} selected={false} onClick={editNote}>
                  <span>Edit</span>
              </MenuItem>
              <MenuItem key={2} selected={false} onClick={deleteNote}>
                  <span>Delete</span>
              </MenuItem>
          </Menu>
          
      </div>
  );
}

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    borderBottom: '0px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function ReleaseNoteAccordion({releaseNotes, activeYear}) {
  const [expanded, setExpanded] = React.useState('panel1');
  const [editNote, setEditNote] = React.useState();
  const [cmonths, setCmonths] = React.useState([]);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(()=>{
    if (releaseNotes) {

      let finalMonths = [];
      releaseNotes.map(note=>{
        // let monthName = MonthsIndex[note.month];
        console.log('note.month', note.month);
        if (note?.month && MonthsIndex[note.month] && !finalMonths.includes(MonthsIndex[note.month])) {
          finalMonths = [...finalMonths, MonthsIndex[note.month]];
        }
      })
      console.log('finalMonths', finalMonths)
      setCmonths(finalMonths);
    }
  }, [releaseNotes])

  return (
    <div style={{borderLeft: "1px solid #333333", paddingLeft: "30px"}}>
      {Months?.map((month, index)=>{
        const rindex = index+1;
        if (cmonths.includes(month)) {
          return (
          <Accordion square expanded={expanded === 'panel'+rindex} onChange={handleChange('panel'+rindex)}>
             
             <AccordionSummary aria-controls={`panel${rindex}d-content`} id={`panel${rindex}d-header`}>
                <Typography variant='h3'>{month + ' '+activeYear}</Typography> <ExpandMoreIcon  style={expanded === 'panel'+rindex ? { fontSize: "35px", color: "#333333", transform: "rotate(180deg)" } : { fontSize: "35px", color: "#333333" }} />
             </AccordionSummary>
             <AccordionDetails>
               <div style={{width: "100%"}}>
               {releaseNotes.map((rnote)=>{
                 if (parseInt(rnote.month) === rindex) {
                 return (<div style={{marginBottom: "25px", paddingTop: "20px", borderTop: "1px solid #a1a1a1"}}>
                           <div style={{display: "flex", justifyContent: "space-between"}}>
                             <Typography variant='h4'>{rnote.title}</Typography><RowActionMenu setEditNote={setEditNote} note={rnote} />
                           </div>
                           {rnote.description && <div dangerouslySetInnerHTML={{ __html: rnote.description }}></div>}
                         </div>)
                 }})}
                 </div>
             </AccordionDetails>
          </Accordion>
         )
        }
      })}
      <AddReleaseNoteModal editNote={editNote} open={editNote} onClose={()=>setEditNote(null)} />
      
    </div>
  );
}
