import { Button, CircularProgress, Container, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import ReleaseNoteAccordion from './ReleaseNoteAccordion';
import MuiButton from '@material-ui/core/Button';
import AddReleaseNoteModal from './AddReleaseNoteModal';
import { wordpressBaseUrl } from 'settings';
import Axios from 'axios';

const relsNotes = [
   {
      title: "Test title 01",
      year: 2024,
      description: `
      <h3>Test subheading 01</h3>
      <p>test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 </p>
      <h3>Test subheading 01</h3>
      <p>test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 </p>`
      
   },
   {
      title: "Test title 02",
      year: 2024,
      description: `
      <h3>Test subheading 02</h3>
      <p>test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 </p>
      <h3>Test subheading 01</h3>
      <p>test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 </p>
      `
   },
   {
      title: "Test title 01",
      year: 2023,
      description: `
      <h3>Test subheading 01 - 2023</h3>
      <p>test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 </p>
      <h3>Test subheading 01</h3>
      <p>test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 </p>
      `
   },
   {
      title: "Test title 02",
      year: 2023,
      description: `
      <h3>Test subheading 02 - 2023</h3>
      <p>test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 </p>
      <h3>Test subheading 01</h3>
      <p>test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 </p>
      `
   },
   {
      title: "Test title 01",
      year: 2022,
      description: `
      <h3>Test subheading 01 - 2022</h3>
      <p>test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 </p>
      <h3>Test subheading 01</h3>
      <p>test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 </p>
      `
   },
   {
      title: "Test title 02",
      year: 2022,
      description: `
      <h3>Test subheading 02 - 2022</h3>
      <p>test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 </p>
      <h3>Test subheading 01</h3>
      <p>test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 test description 01 </p>
      `
   }
];

const ReleaseNotes = () => {
   const [allNotes, setAllNotes] = useState();
   const [relNotes, setRelNotes] = useState([]);
   const [activeYear, setActiveYear] = useState(2024);
   const [showAddModal, setShowAddModal] = useState(false);

   useEffect(()=>{
      if (allNotes && allNotes.length > 0) {
         const filterNotes = allNotes.filter(note=>parseInt(note.year) === parseInt(activeYear))
         // let finalNotes = {};
         // finalNotes['others'] = [];
         // filterNotes.map(fnote=>{
         //    if (fnote.month) {
         //       finalNotes[fnote.month] = [...finalNotes[fnote.month], fnote];
         //    } else {
         //       finalNotes['others'] = [...finalNotes['others'], fnote];
         //    }
         // });
         // console.log('finalNotes', finalNotes)
         setRelNotes(filterNotes);
      }
   }, [activeYear, allNotes]);

   const yearExists = (relnots, year) => {
      console.log('relnots', relnots)
      let result = false;
      relnots.map(rn=>{
         if (parseInt(rn.year) === year) {
            result = true;
         }
      });
      return result;
   }

   useEffect(()=>{
      getReleaseNotes();
   }, [])

   const getReleaseNotes = async() => {
      // Make an API call to your custom image upload endpoint
      const response = await Axios.get(`${wordpressBaseUrl}/wp-json/hh-api/admin/get-release-notes`);
      console.log('response', response.data.data);
      if (response.data.success) {
         setAllNotes(response.data.data)
      }
   }
   if (!allNotes) {
      return (<Container style={{display: "flex", justifyContent: "center"}}><CircularProgress /></Container>);
   }
  return (<React.Fragment>
    <div style={{borderBottom: "1px solid #333333", marginBottom: "20px", paddingBottom: "20px"}}>
      <Typography variant="h2" color="inherit" className="link-breadcrumb">
      Release Notes
      </Typography>
      <MuiButton onClick={()=>setShowAddModal(true)} variant="contained" color="primary" style={{ float: "right", marginRight: 8 }}>Add </MuiButton>
      <Typography variant="subtitle1">
      Stay up to date with the latest from Headshots.com through our Release Notes. Here you’ll find a comprehensive summary of all new features, resolved issues, and known issues in our latest product and service updated</Typography>
    </div>
    <Grid container spacing={2}>
      <Grid item lg={2} md={3} xs={12}>
         {relNotes?.length > 0 && <div>
         {yearExists(allNotes, 2024) && <Typography variant='subtitle1' style={{textAlign: "center", color: "#333333", fontWeight: "600", textDecoration: "underline"}}><Button onClick={()=>setActiveYear(2024)} color="primary" style={activeYear === 2024 ? {textAlign: "center", color: "#333333", fontWeight: "600", textDecoration: "underline", fontSize: "18px"} : {textAlign: "center", fontSize: "18px", color: "#333333"}}>2024</Button></Typography>}
         {yearExists(allNotes, 2023) && <Typography variant='subtitle1' style={{textAlign: "center"}}><Button onClick={()=>setActiveYear(2023)} color="primary" style={activeYear === 2023 ? {textAlign: "center", color: "#333333", fontWeight: "600", textDecoration: "underline", fontSize: "18px"} : {textAlign: "center", fontSize: "18px", color: "#333333"}}>2023</Button></Typography>}
         {yearExists(allNotes, 2022) && <Typography variant='subtitle1' style={{textAlign: "center"}}><Button onClick={()=>setActiveYear(2022)} color="primary" style={activeYear === 2022 ? {textAlign: "center", color: "#333333", fontWeight: "600", textDecoration: "underline", fontSize: "18px"} : {textAlign: "center", fontSize: "18px", color: "#333333"}}>2022</Button></Typography>}
         </div>}
      </Grid>
      <Grid item lg={10} md={9} xs={12}>
         <ReleaseNoteAccordion releaseNotes={relNotes} activeYear={activeYear} />
      </Grid>
   </Grid>
   <AddReleaseNoteModal open={showAddModal} onClose={()=>setShowAddModal(false)} />
   </React.Fragment>
  )
}

export default ReleaseNotes