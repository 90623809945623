import React from 'react';
import Breadcrumb from '../../component/Breadcrumb';
import { Typography } from '@material-ui/core';
import { CircularProgress, Card, Select, MenuItem, IconButton, Grid, InputBase, Button } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete'
import axios from 'axios';
import { parseAndFormatDateTime } from '../../utils/date';
import { wordpressBaseUrl } from 'settings';
import ModalConfirmation from './ModalConfirmation';
import ReplyRoundedIcon from '@material-ui/icons/ReplyRounded';
import useAuth from 'hooks/useAuth';
import EXIF from 'exif-js';
import { CloudDownloadOutlined, OpenInBrowserOutlined } from '@material-ui/icons';
import MouseOverPopover from 'component/_hh/Popover';
import { getImageNameFromUrl } from '../../utils/images';
import ShowGuidelineMenu from './GuidelineDropdown';
import AssignView from './AssignView';
const fabric = require("fabric").fabric;

function withAuth(Component) {
    return function WrappedComponent(props) {
        const auth = useAuth();
        return <Component {...props} auth={auth} />;
    }
}
class PhotoView extends React.Component {
    state = {
        hero: {},
        requests: [],
        selectedRequest: null,
        selectedPhoto: null,
        currentComments: [],
        isAddingComment: false,
        currentCommentInput: "",
        lastRectangle: null,
        fabricObjects: [],
        commentInput: "",
        editor: null,
        comment_id_to_delete: null,
        images: [],
        requests: [],
        showGuidelineImg: false,
        guidelineImg: `https://www.dropbox.com/s/ay07gl9vyfi17oi/Standard%20Guidelines.png?raw=1`,
        addGuidelineImg: null,
        showGuideline: null,
        loading: false,
        imageLoaded: false
    };

    timeout = 0;

    async componentDidMount() {
        if (this.props.match) {
            document.title = "Hero Image - " + this.props.match.params.id;
        }
        if (this.props.match && this.props.match.params.editor_id) {
            const response = await axios.get(`${wordpressBaseUrl}/wp-json/hh-api/get-editor-detail?unique_key=${this.props.match.params.editor_id}`);
            if (response.data?.editor?.id) {
                this.setState({
                    editor: response.data.editor,
                });
            } else {
                this.props.history.push("/");
            }
            // add global css
            const css = document.createElement("style");
            css.type = "text/css";
            css.innerHTML = `

                body {
                    background-color: #f0f2f8;
                }

                #root {
                    padding: 40px
                }
            `;
            document.body.appendChild(css);
        }

        await this.getHero();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedHero !== this.props.selectedHero) {
            this.setState({
                isLoading: true
            });
            const allimages = this.props.selectedHero.request.uploads.map(upload => ({
                path: upload.path,
                name: /[^/]*$/.exec(upload.path)[0],
                request_upload_id: upload.id,
                type: upload.type,
                ...upload
            }));

            this.setState({
                hero: this.props.selectedHero.hero,
                selectedRequest: this.props.selectedHero.request,
                selectedPhoto: allimages[0],
                images: allimages,
                isLoading: false
            }, () => {
                this.loadNewPhoto(allimages[0].name)
            });
        }
    }

    loadNewPhoto = async (filename) => {
        var selectedPhoto = null;
        var selectedRequest = null;

        var foundUpload = this.state.images.find(r => r.name === filename);
        if (foundUpload) {
            // console.log('foundUpload', foundUpload)
            selectedPhoto = foundUpload;
            selectedRequest = this.state.requests.find(r => r.id === foundUpload.request_id);
        }

        selectedRequest.comments.forEach(c => {
            if (c.data && c.data != "" && Object.prototype.toString.call(c.data) === "[object String]") {
                c.data = JSON.parse(c.data);
            }
        });

        // this.state.fabricObjects.forEach(o => {
        //     this.state.canvas.remove(o.rect);
        //     this.state.canvas.remove(o.text);
        // });
        if (this.state.canvas) {
            this.state.canvas.remove(...this.state.canvas.getObjects());
            this.state.canvas.renderAll();
        }

        if (this.props.match && this.props.match.params.id) {
            window.history.replaceState(null, "Hero Image - " + this.props.match.params.id, `/heroes/uploads/${this.props.match.params.id}/${selectedPhoto.name}`)
        }

        this.setState({
            selectedRequest: selectedRequest,
            selectedPhoto: selectedPhoto,
            isLoading: false
        }, () => {
            this.initCanvas();
        });
    }

    getHero = async () => {
        if (this.state.canvas) {
            this.state.canvas.remove(...this.state.canvas.getObjects());
            this.state.canvas.renderAll();
        }
        this.setState({ isLoading: true });
        const fileName = this.props.filename || this.props?.match?.params?.filename;
        const id = this.props.hero_public_id || this.props.match.params.id;
        const urlParams = new URLSearchParams(window.location.search);
        const reqid = urlParams.get('reqid');
        console.log('reqid', reqid)
        const url = `${wordpressBaseUrl}/wp-json/hh-api/get-hero-details?public_id=${id}&resolution=true`;
        const heroResponse = await axios.get(url);

        document.title = "Hero " + heroResponse.data.hero.public_id;

        const allimages = heroResponse.data.requests?.flatMap(request => {
            // console.log('request', request)
            //  if ((reqid && parseInt(request.id) === parseInt(reqid)) || !reqid) {
            return request.uploads.map(upload => ({
                path: upload.path,
                name: /[^/]*$/.exec(upload.path)[0],
                request_upload_id: upload.id,
                type: upload.type,
                ...upload
            }))
            //  }
        }
        );
        const finalImages = allimages.filter(img => img !== undefined);
        const images = finalImages.filter((image, index, self) => self.findIndex(i => i.name === image.name) === index);

        // for images saved before 6/13 that don't have records in hh_request_uploads table. will be removed later
        if (!images.find(i => i.type === "edited_image_path_transparent")) {
            heroResponse.data.requests.forEach(request => {
                if (request.edited_image_path_transparent) {
                    images.push({
                        path: request.edited_image_path_transparent,
                        name: /[^/]*$/.exec(request.edited_image_path_transparent)[0],
                        request_upload_id: null,
                        type: "edited_image_path_transparent",
                        request_id: request.id
                    });
                }
            });
        }
        if (!images.find(i => i.type === "edited_image_path")) {
            heroResponse.data.requests.forEach(request => {
                if (request.edited_image_path) {
                    images.push({
                        path: request.edited_image_path,
                        name: /[^/]*$/.exec(request.edited_image_path)[0],
                        request_upload_id: null,
                        type: "edited_image_path",
                        request_id: request.id
                    });
                }
            });
        }
        if (!images.find(i => i.type === "image_path")) {
            heroResponse.data.requests.forEach(request => {
                if (request.image_path) {
                    images.push({
                        path: request.image_path,
                        name: /[^/]*$/.exec(request.image_path)[0],
                        request_upload_id: null,
                        type: "image_path",
                        request_id: request.id
                    });
                }
            });
        }
        var selectedPhoto = images.find(i => i.name == encodeURIComponent(fileName)) || images[0];
        // console.log('images', selectedPhoto)
        var selectedRequest = heroResponse.data?.requests?.find(r => r.id === selectedPhoto.request_id);

        var comments = [];
        heroResponse.data.requests.forEach(r => {
            r.comments.forEach(c => {
                if (c.data && c.data != "" && Object.prototype.toString.call(c.data) === "[object String]") {
                    c.data = JSON.parse(c.data);
                }
                comments.push(c);
            });
        });
        selectedRequest.comments = comments;
        if (heroResponse.data.hero?.team?.guidelines) {
            try {
                const guidelineObj = JSON.parse(heroResponse.data.hero?.team?.guidelines);
                if (guidelineObj.guidelines) this.setState({ guidelineImg: guidelineObj.guidelines });
                if (guidelineObj.additional_guidelines) this.setState({ addGuidelineImg: guidelineObj.additional_guidelines });
            } catch (e) { console.log('error', e) }
        }
        this.setState({
            hero: heroResponse.data.hero,
            requests: heroResponse.data.requests,
            selectedRequest: selectedRequest,
            selectedPhoto: selectedPhoto,
            images: images,
            isLoading: false
        }, () => {
            this.initCanvas();
        });


    };

    initCanvas = async () => {
        var canvas = this.state.canvas;

        if (canvas == null) {
            var canvId = this.props.canvasId ? this.props.canvasId : "c";
            canvas = new fabric.Canvas(canvId, { selection: false });
        }

        // canvas.setWidth(document.getElementById("c").offsetWidth);
        const canvasGrid = document.getElementById("canvasContainer");
        console.log('canvasGrid.offsetWidth', canvasGrid.offsetWidth)
        const cWidth = canvasGrid.offsetWidth > 800 ? 800 : (canvasGrid.offsetWidth);
        canvas.setWidth(cWidth);
        // canvas.setHeight("350");

        // reset viewpoint transform
        canvas.viewportTransform = [1, 0, 0, 1, 0, 0];

        canvas.setBackgroundColor('#333333', canvas.renderAll.bind(canvas));

        await this.loadImageOnCanvas(canvas, this.state.selectedPhoto.path)



        var rect, isDown, origX, origY, lastPosX, lastPosY, isDragging = false;

        canvas.on('mouse:down', (o) => {
            if (this.props.imageOnly)
                return;

            lastPosX = o.e.clientX;
            lastPosY = o.e.clientY;


            var pointer = canvas.getPointer(o.e);
            origX = pointer.x;
            origY = pointer.y;

            if (o.e.altKey === true) {
                isDragging = true;
                this.selection = false;

                return
            }
            isDragging = false;

            isDown = true;

            rect = new fabric.Rect({
                left: origX,
                top: origY,
                originX: 'left',
                originY: 'top',
                width: pointer.x - origX,
                height: pointer.y - origY,
                angle: 0,
                fill: 'rgba(255,0,0,0)',
                transparentCorners: false,
                stroke: 'red',
                strokeWidth: 2,
                hoverCursor: 'cursor',
            });
            canvas.add(rect);


        });

        canvas.on('mouse:move', (o) => {

            if (isDragging) {
                var pointer = canvas.getPointer(o.e);
                var e = o.e;
                var vpt = canvas.viewportTransform;
                vpt[4] += e.clientX - lastPosX;
                vpt[5] += e.clientY - lastPosY;
                canvas.renderAll();
                lastPosX = o.e.clientX;
                lastPosY = o.e.clientY;

                return;
            }

            if (!isDown) return;
            var pointer = canvas.getPointer(o.e);

            if (origX > pointer.x) {
                rect.set({ left: Math.abs(pointer.x) });
            }
            if (origY > pointer.y) {
                rect.set({ top: Math.abs(pointer.y) });
            }

            rect.set({ width: Math.abs(origX - pointer.x) });
            rect.set({ height: Math.abs(origY - pointer.y) });


            canvas.renderAll();


        });

        canvas.on('mouse:up', (o) => {
            if (this.props.imageOnly)
                return;

            if (isDragging) {
                canvas.setViewportTransform(canvas.viewportTransform);
                isDragging = false;
                return;
            }
            isDown = false;
            this.setState({
                isAddingComment: true,
                lastRectangle: rect
            })
        });

        canvas.on('mouse:wheel', function (opt) {
            var delta = opt.e.deltaY;
            var zoom = canvas.getZoom();
            zoom *= 0.999 ** delta;
            if (zoom > 20) zoom = 20;
            if (zoom < 0.01) zoom = 0.01;
            canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
            opt.e.preventDefault();
            opt.e.stopPropagation();
        });

        if (!this.props.imageOnly) {
            this.setState({ canvas: canvas }, () => {
                this.loadCommentRectangles();
            });
        }
    }

    calculateResponsivePosition = (canvasWidth, canvasHeight, cdata) => {
        if (cdata.canvas_width && cdata.canvas_height) {
            const ratioX = canvasWidth / cdata.canvas_width;
            const ratioY = canvasHeight / cdata.canvas_height;
            const finalLeft = cdata.left * ratioX;
            const finalTop = cdata.top * ratioY;
            const finalWidth = cdata.width * ratioX;
            const finalHeight = cdata.height * ratioY;
            return { left: finalLeft, top: finalTop, width: finalWidth, height: finalHeight }
        } else {
            return cdata
        }
    }

    loadCommentRectangles = () => {
        var canvas = this.state.canvas;
        var commentMap = {};
        var rects = this.state.selectedRequest.comments.map(c => {
            if (c.data) {
                console.log(c.data.type, this.state.selectedPhoto.path, this.state.selectedRequest.image_path, this.state.selectedRequest.edited_image_path, this.state.selectedRequest.edited_image_path_transparent)
                if (
                    (c.data.type === "edited_image_path_transparent" && this.state.selectedPhoto.path === this.state.selectedRequest.edited_image_path_transparent) ||
                    (c.data.request_upload_id != null && this.state.selectedPhoto.request_upload_id === c.data.request_upload_id) ||
                    (this.state.selectedPhoto.path.includes(c.data.filename))) {
                    // console.log('canvas width', canvas.getWidth());
                    var responsivePos = this.calculateResponsivePosition(canvas.getWidth(), canvas.getHeight(), c.data);
                    var rect = new fabric.Rect({
                        left: responsivePos.left,
                        top: responsivePos.top,
                        originX: 'left',
                        originY: 'top',
                        width: responsivePos.width,
                        height: responsivePos.height,
                        angle: 0,
                        fill: 'rgba(255,0,0,0)',
                        transparentCorners: false,
                        stroke: 'red',
                        strokeWidth: 2,
                        hoverCursor: 'cursor',
                        selectable: false,

                    });
                    canvas.add(rect);

                    // add comment text
                    var text = new fabric.Text(c.comment, {
                        left: responsivePos.left,
                        top: responsivePos.top + responsivePos.height + 10,
                        fontSize: 12,
                        fontFamily: 'Arial',
                        fill: 'black',
                        originX: 'left',
                        originY: 'top',
                        hoverCursor: 'cursor',
                        selectable: false,
                        backgroundColor: 'white',
                    });
                    canvas.add(text);

                    return {
                        rect: rect,
                        text: text,
                        request_comment_id: c.request_comment_id
                    };
                }


            }
            return null;
        }).filter(r => r);

        rects.forEach(r => {
            commentMap[r.request_comment_id] = r;
        });
        this.setState({ canvas: canvas, fabricObjects: rects });
    }

    getImageXHR = (url) => {
        return new Promise((resolve, reject) => {
            try {
                var xhr = new XMLHttpRequest();
                xhr.open("GET", url + "?cacheblock=true", true);
                xhr.responseType = "arraybuffer";
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            resolve(xhr);
                        }
                        else {
                            reject(xhr.status);
                        }
                    }

                };
                xhr.send(null);
            } catch (e) {
                console.log("error", e);
                reject(e);
            }
        });
    }

    heicToLocalUrl = async (heicUrl) => {
        // Create promise to handle async operations
        return new Promise(async (resolve, reject) => {
            let tempCanvas = document.createElement('canvas');
            try {
                // First load libheif WASM
                const wasmResponse = await fetch('/libheif.wasm');
                const wasmBinary = await wasmResponse.arrayBuffer();
                const libheifInstance = window.libheif({ wasmBinary: wasmBinary });

                // Create decoder instance
                const decoder = new libheifInstance.HeifDecoder();

                // Fetch the HEIC image
                const response = await fetch(heicUrl);
                if (!response.ok) {
                    throw new Error(`Failed to fetch image: ${response.status}`);
                }

                const arrayBuffer = await response.arrayBuffer();
                const buffer = new Uint8Array(arrayBuffer);

                // Decode the HEIC image
                const decodedImages = decoder.decode(buffer);
                if (!decodedImages || !decodedImages.length) {
                    throw new Error('Failed to decode HEIC image');
                }

                // Create canvas to draw the image
                const canvas = tempCanvas;
                const ctx = canvas.getContext('2d');

                // Get first image from decoded data
                const image = decodedImages[0];
                const width = image.get_width();
                const height = image.get_height();

                // Set canvas dimensions
                canvas.width = width;
                canvas.height = height;

                // Create image data
                const imageData = ctx.createImageData(width, height);

                // Display image on canvas
                image.display(imageData, (displayImageData) => {
                    if (!displayImageData) {
                        reject(new Error('Failed to process image data'));
                        return;
                    }

                    // Put image data on canvas
                    ctx.putImageData(displayImageData, 0, 0);

                    // Convert canvas to blob
                    canvas.toBlob((blob) => {
                        // Create local URL from blob
                        const localUrl = URL.createObjectURL(blob);

                        // Clean up
                        decodedImages.forEach(img => img.free());

                        // Clean up canvas
                        tempCanvas.width = 0;
                        tempCanvas.height = 0;
                        tempCanvas.remove();
                        tempCanvas = null;

                        // Return the local URL
                        resolve(localUrl);
                    }, 'image/jpeg');
                });

            } catch (e) {
                console.log("error converting heic", e)
                if (tempCanvas) {
                    tempCanvas.width = 0;
                    tempCanvas.height = 0;
                    tempCanvas.remove();
                    tempCanvas = null;
                }
                reject(e);
            }
        });
    }

    loadImageOnCanvas = async (canvas, imageUrl) => {
        return new Promise(async (resolve, reject) => {

            // run image through backend to bypass CORS limitation
            const isAws = imageUrl.includes("s3.amazonaws.com");
            let isHeic = imageUrl.toLowerCase().endsWith(".heic");
            if (isAws && !isHeic) {
                const fileName = getImageNameFromUrl(imageUrl);
                imageUrl = `${wordpressBaseUrl}/wp-json/hh-api/get-image-aws?hero_image=${fileName}`;
            }

            var image = new Image();
            if (isHeic) {
                this.setState({ isLoading: "Converting HEIC" })
                image.src = await this.heicToLocalUrl(imageUrl);

            } else {
                image.src = imageUrl;
            }

            image.onload = async () => {
                // get image dimensions
                var imageWidth = image.width;
                var imageHeight = image.height;
                // console.log('natural image', image.naturalWidth)
                // load file size here asynchrnously if not heic for faster loading
                if (!isHeic) {
                    this.getImageXHR(this.state.selectedPhoto.path).then((imageXHR) => {
                        const imageResponse = imageXHR.response;
                        var fileSize = imageResponse.byteLength / 1024 / 1024;
                        if (fileSize > 0) {
                            this.setState({ imageFileSize: fileSize.toFixed(2) });
                        }
                        var mimeType = imageXHR.getResponseHeader("Content-Type");
                        let f = new File([imageResponse], this.state.selectedPhoto.path, { type: mimeType });
                        var objectURL = URL.createObjectURL(f);
                        this.setState({ objectURL });
                    });
                }

                // get image aspect ratio
                var imageAspectRatio = imageWidth / imageHeight;
                // console.log('this.state.selectedPhoto', this.state.selectedPhoto)
                var primaryDpi = this.state.selectedPhoto.resoluation ? this.state.selectedPhoto.resoluation[0] : image.width / (image.naturalWidth || 1);
                this.setState({
                    imageMetadata: {
                        width: imageWidth,
                        height: imageHeight,
                        orientation: imageWidth > imageHeight ? 'Horizontal (normal)' : 'Vertical (normal)',
                        dpi: primaryDpi
                    }

                })

                EXIF.getData(image
                    , () => {
                        var allMetaData = EXIF.getAllTags(image);
                        var orientation = ''
                        if (allMetaData.Orientation) {
                            orientation = allMetaData.Orientation
                        } else {
                            if (imageWidth > imageHeight) {
                                orientation = 1
                            } else {
                                orientation = 3
                            }
                        }
                        var rotation = "";
                        if (orientation === 1) {
                            rotation = "Horizontal (normal)";
                        } else if (orientation === 3) {
                            rotation = "Vertical (normal)";
                        }

                        // calculate dpi
                        var dpi = Math.sqrt(Math.pow(imageWidth, 2) + Math.pow(imageHeight, 2)) / Math.sqrt(Math.pow(allMetaData.XResolution, 2) + Math.pow(allMetaData.YResolution, 2));
                        if (allMetaData.XResolution) {
                            dpi = allMetaData.XResolution
                        }
                        // calculate color profile
                        var colorProfile = "";
                        if (allMetaData.ColorSpace === 1) {
                            colorProfile = "sRGB";
                        } else {
                            colorProfile = "Uncalibrated";
                        }

                        //console.log('allMetaData', allMetaData, image.naturalWidth, imageWidth)

                        this.setState({
                            imageMetadata: {
                                width: imageWidth,
                                height: imageHeight,
                                orientation: rotation,
                                dpi: dpi ? dpi : (primaryDpi ? primaryDpi : ''),
                                whiteBalance: allMetaData.WhiteBalance,
                                cameraBrand: allMetaData.Make,
                                cameraModel: allMetaData.Model,
                                flash: allMetaData.Flash,
                                colorProfile: colorProfile,

                            }

                        })
                    });

                // get canvas dimensions
                var canvasWidth = canvas.getWidth();
                canvas.setHeight((canvasWidth * (imageHeight / imageWidth)));
                var canvasHeight = canvas.getHeight();
                // get canvas aspect ratio
                var canvasAspectRatio = canvasWidth / canvasHeight;

                // calculate image dimensions
                var imageWidthToUse = 0;
                var imageHeightToUse = 0;
                if (imageAspectRatio > canvasAspectRatio) {
                    imageWidthToUse = canvasWidth;
                    imageHeightToUse = canvasWidth / imageAspectRatio;
                } else {
                    imageWidthToUse = canvasHeight * imageAspectRatio;
                    imageHeightToUse = canvasHeight;
                }



                var img = new fabric.Image(image);
                img.hoverCursor = 'cursor';
                img.selectable = false;
                img.set({
                    // set to bottom
                    top: canvasHeight - imageHeightToUse,
                    left: canvasWidth / 2 - imageWidthToUse / 2,
                });
                img.scaleToWidth(imageWidthToUse);
                img.scaleToHeight(imageHeightToUse);
                canvas.add(img).renderAll();
                this.setState({ imageLoaded: true, isLoading: false })
                resolve();
            };
        });
    }

    addComment = async (comment) => {
        var canvas = this.state.canvas;
        var rect = this.state.lastRectangle;
        var requestId = this.state.selectedRequest.id;
        var senderId = null;
        this.setState({
            loading: true
        })
        if (this.state.editor) {
            senderId = this.state.editor.name;
        } else {
            senderId = this.props.auth.user.user_nicename;
        }
        // console.log("canvas width", canvas.getWidth());
        var commentObj = {
            "request_id": requestId,
            "comment": comment,
            "sender_id": senderId,

            "data": rect ? JSON.stringify({
                left: this.state.lastRectangle.left,
                top: this.state.lastRectangle.top,
                width: this.state.lastRectangle.width,
                height: this.state.lastRectangle.height,
                canvas_width: canvas.getWidth(),
                canvas_height: canvas.getHeight(),
                "type": this.state.selectedPhoto.type,
                "request_upload_id": this.state.selectedPhoto.request_upload_id,
                "filename": this.state.selectedPhoto.name,
                "uploaded_at": this.state.selectedPhoto.created_at
            }) : JSON.stringify({
                "type": this.state.selectedPhoto.type,
                "request_upload_id": this.state.selectedPhoto.request_upload_id,
                "filename": this.state.selectedPhoto.name,
                "uploaded_at": this.state.selectedPhoto.created_at
            })
        }

        const url = `${wordpressBaseUrl}/wp-json/hh-api/create-request-comment`;
        const commentResponse = await axios.post(url, commentObj);

        if (commentResponse.data) {
            this.setState({
                isAddingComment: false,
                currentCommentInput: "",
                commentInput: "",
                lastRectangle: null,
                loading: false
            }, () => {
                this.getHero();
            });
        }


    }

    deleteComment = async () => {
        var commentId = this.state.comment_id_to_delete;

        const url = `${wordpressBaseUrl}/wp-json/hh-api/delete-request-comment`;
        const commentResponse = await axios.post(url, {
            "comment_id": commentId,
        });

        if (commentResponse.data.success) {
            this.setState({
                comment_id_to_delete: null,
            }, () => {
                this.getHero();
            });
        }


    }

    canDeleteComment = (comment) => {
        if (!comment) return false;

        if (this.state.editor && this.state.editor.name === comment.sender_id) {
            return true;
        }

        return comment.sender_id === this.props.auth?.user?.user_nicename;
    }

    downloadFile = (file) => {
        if (this.state.selectedPhoto.path?.toLowerCase()?.endsWith('.heic')) {
            window.open(this.state.selectedPhoto.path, '_blank');
            return;
        }

        window.location.href = `${wordpressBaseUrl}/wp-json/hh-api/get-image-aws?download=1&hero_image=${/[^/]*$/.exec(this.state.selectedPhoto.path)[0]}`;
    }

    deleteUploadedImage = async (id) => {
        // var rconfirm = confirm('Are you sure you want to upload the upload file');
        if (window.confirm('Are you sure you want to upload the upload file')) {
            console.log('delete req id', id)
            const res = await axios.post(`${wordpressBaseUrl}/wp-json/hh-api/delete-request-upload`, { request_upload_id: id });
            // console.log('upload res', res)
            if (res) {
                window.location.href = '/heroes/detail/' + this.state.hero.id;
            }
        }
    }

    handleGuidelineClick = (type = null) => {
        // console.log('type', type)
        if (type) {
            if (!this.state.showGuidelineImg) this.setState({ showGuidelineImg: true })
            this.setState({ showGuideline: type })
        } else {
            this.setState({ showGuidelineImg: false })
        }
    }
    render() {
        const { hero, images, selectedPhoto, isAddingComment, currentCommentInput, selectedRequest, commentInput, guidelineImg, showGuidelineImg, addGuidelineImg, showGuideline } = this.state;
        // console.log('this.state.imageLoaded', this.state.imageLoaded)
        if (this.props.imageOnly) {
            return (<>
                <div style={{ position: "relative", ...this.props.style }}>
                    {this.state.isLoading && <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", background: "rgba(255,255,255,0.5)", zIndex: 1000, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress />
                    </div>}
                    <>{(showGuidelineImg && showGuideline === 'standard') ? (<div style={{ background: `url(${guidelineImg.replace('?dl=0', '?raw=1')})`, backgroundSize: "contain", backgroundPosition: "center", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat" }} className='guidleline-image'></div>) : ""}</>
                    <div>{(showGuidelineImg && showGuideline === 'additional') ? (<div style={{ background: `url(${addGuidelineImg})`, backgroundSize: "contain", backgroundPosition: "center", backgroundSize: "100% 100%", backgroundRepeat: 'no-repeat' }} className='guidleline-image'></div>) : ''}</div>
                    <div id="canvasContainer">
                        <canvas width="800px" height="500px" id={this.props.canvasId ? this.props.canvasId : "c"} />
                    </div>
                </div>
                {this.state.imageMetadata && <Card style={{ marginTop: 20, padding: 20, width: "100%" }}>
                    <Typography variant="h6" color="primary" style={{ marginBottom: 6 }}>
                        Image Metadata
                    </Typography>
                    <Typography variant="subtitle2" color="inherit">
                        <b> Size:</b> {this.state.imageFileSize}mb
                    </Typography>
                    <Typography variant="subtitle2" color="inherit">
                        <b> Dimensions:</b> {this.state.imageMetadata.width} x {this.state.imageMetadata.height}
                    </Typography>
                    <Typography variant="subtitle2" color="inherit">
                        <b> Orientation:</b> {this.state.imageMetadata.orientation}
                    </Typography>
                    {this.state.imageMetadata.dpi && (
                        <Typography variant="subtitle2" color="inherit">
                            <b>DPI:</b> {Math.trunc(this.state.imageMetadata.dpi)}
                        </Typography>
                    )}
                    {this.state.imageMetadata.colorProfile && (
                        <Typography variant="subtitle2" color="inherit">
                            <b>Color Profile:</b> {this.state.imageMetadata.colorProfile}
                        </Typography>
                    )}
                    {this.state.imageMetadata.whiteBalance && (
                        <Typography variant="subtitle2" color="inherit">
                            <b>White Balance:</b> {this.state.imageMetadata.whiteBalance}
                        </Typography>
                    )}
                    {this.state.imageMetadata.cameraBrand && (
                        <Typography variant="subtitle2" color="inherit">
                            <b>Camera Make:</b> {this.state.imageMetadata.cameraBrand}
                        </Typography>
                    )}
                    {this.state.imageMetadata.cameraModel && (
                        <Typography variant="subtitle2" color="inherit">
                            <b>Camera Model:</b> {this.state.imageMetadata.cameraModel}
                        </Typography>
                    )}
                    {this.state.imageMetadata.flash && (
                        <Typography variant="subtitle2" color="inherit">
                            <b>Flash:</b> {this.state.imageMetadata.flash}
                        </Typography>
                    )}
                </Card>}</>
            )
        }
        return (
            <React.Fragment>
                {this.props.match &&
                    <Breadcrumb title="Hero Images">
                        <Typography variant="subtitle2" color="inherit" className="link-breadcrumb">
                            Heroes
                        </Typography>
                        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
                            <a href={'/heroes/detail/' + hero.id}>{hero.public_id}</a>
                        </Typography>
                        <Typography variant="subtitle2" color="inherit" className="link-breadcrumb">
                            Uploads
                        </Typography>
                    </Breadcrumb>}

                <div style={{ marginBottom: 20, display: "flex" }}>
                    <Select
                        style={{ width: "calc(100% - 14rem" }}
                        value={selectedPhoto && selectedPhoto.name}
                        onChange={(e, c) => { this.loadNewPhoto(e.target.value) }}
                        displayEmpty
                    >
                        {images?.map(image =>
                            <MenuItem key={image.path} value={image.name} >
                                {image.name}
                            </MenuItem>
                        )}
                    </Select>

                    <MouseOverPopover content="Open in browser"><Button onClick={() => { window.open(selectedPhoto.path, "_blank") }} style={{ marginLeft: 8 }} variant="contained" color="primary"><OpenInBrowserOutlined /></Button></MouseOverPopover>
                    <MouseOverPopover content="Download"><Button onClick={() => { this.downloadFile() }} style={{ marginLeft: 8 }} variant="contained" color="primary"><CloudDownloadOutlined /></Button></MouseOverPopover>
                    <ShowGuidelineMenu addGuidelineImg={addGuidelineImg} handleClick={this.handleGuidelineClick} />
                </div>

                <div>
                    {this.props.assignView ?
                        <AssignView selectedHero={this.props.selectedHero}
                            stateData={this.state}
                            setShowHoldModal={this.props.setShowHoldModal}
                            setShowAssignEditorModal={this.props.setShowAssignEditorModal}
                            setShowRequestResubmitModal={this.props.setShowRequestResubmitModal}
                            updateHold={this.props.updateHold}
                            deleteRequest={this.props.deleteRequest}
                        />
                        : <Grid container spacing={3} justify={'center'}>
                            <Grid item style={{ width: "100%", maxWidth: "800px" }} id="canvasGrid">
                                <Card id="canvasContainer" style={{ position: "relative" }}>
                                    {this.state.isLoading && <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", background: "rgba(255,255,255,0.5)", zIndex: 1000, display: "flex", justifyContent: "center", alignItems: "center", fontSize: 20 }}>
                                        <div style={{position: "absolute", top: "25%"}}>{this.state.isLoading != true && this.state.isLoading}</div>
                                        <CircularProgress />
                                    </div>}
                                    <>{(showGuidelineImg && showGuideline === 'standard') ? (<div style={{ background: `url(${guidelineImg.replace('?dl=0', '?raw=1')})`, backgroundSize: "contain", backgroundPosition: "center", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat" }} className='guidleline-image'></div>) : ""}</>
                                    <div>{(showGuidelineImg && showGuideline === 'additional') ? (<div style={{ background: `url(${addGuidelineImg})`, backgroundSize: "contain", backgroundPosition: "center", backgroundSize: "100% 100%", backgroundRepeat: 'no-repeat' }} className='guidleline-image'></div>) : ''}</div>
                                    <canvas width="800px" height="500px" id={this.props.canvasId ? this.props.canvasId : "c"} />
                                </Card>
                                {/* Display image metadata */}
                                {this.state.imageMetadata && <Card style={{ marginTop: 20, padding: 20 }}>
                                    <Typography variant="h6" color="primary" style={{ marginBottom: 6 }}>
                                        Image Metadata
                                    </Typography>
                                    <Typography variant="subtitle2" color="inherit">
                                        <b> Size:</b> {this.state.imageFileSize}mb
                                    </Typography>
                                    <Typography variant="subtitle2" color="inherit">
                                        <b> Dimensions:</b> {this.state.imageMetadata.width} x {this.state.imageMetadata.height}
                                    </Typography>
                                    <Typography variant="subtitle2" color="inherit">
                                        <b> Orientation:</b> {this.state.imageMetadata.orientation}
                                    </Typography>
                                    {this.state.imageMetadata.dpi && (
                                        <Typography variant="subtitle2" color="inherit">
                                            <b>DPI:</b> {Math.trunc(this.state.imageMetadata.dpi)}
                                        </Typography>
                                    )}
                                    {this.state.imageMetadata.colorProfile && (
                                        <Typography variant="subtitle2" color="inherit">
                                            <b>Color Profile:</b> {this.state.imageMetadata.colorProfile}
                                        </Typography>
                                    )}
                                    {this.state.imageMetadata.whiteBalance && (
                                        <Typography variant="subtitle2" color="inherit">
                                            <b>White Balance:</b> {this.state.imageMetadata.whiteBalance}
                                        </Typography>
                                    )}
                                    {this.state.imageMetadata.cameraBrand && (
                                        <Typography variant="subtitle2" color="inherit">
                                            <b>Camera Make:</b> {this.state.imageMetadata.cameraBrand}
                                        </Typography>
                                    )}
                                    {this.state.imageMetadata.cameraModel && (
                                        <Typography variant="subtitle2" color="inherit">
                                            <b>Camera Model:</b> {this.state.imageMetadata.cameraModel}
                                        </Typography>
                                    )}
                                    {this.state.imageMetadata.flash && (
                                        <Typography variant="subtitle2" color="inherit">
                                            <b>Flash:</b> {this.state.imageMetadata.flash}
                                        </Typography>
                                    )}
                                </Card>}
                            </Grid>
                            {selectedRequest && <Grid item md={12} lg style={{ minHeight: "500px", minWidth: "300px", width: "100%" }}>

                                <Card style={{ height: "100%", position: "relative" }}>
                                    <div style={{ overflowY: 'auto', height: '500px', marginBottom: '80px', marginTop: 2 }}>
                                        {selectedRequest.comments.map((comment) => (
                                            <div key={"c" + comment.id} style={{
                                                backgroundColor: "#f5f5f5",
                                                padding: "14px",
                                                borderRadius: "5px",
                                                margin: 8,
                                                marginTop: 15,
                                                whiteSpace: "break-spaces",
                                            }}>
                                                <b>{comment.editor_name}</b> {comment.data?.filename ? <small style={{ color: "#b0b0b0", marginLeft: 6 }}>{comment.data.filename}</small> : ''}{comment.data?.uploaded_at ? <small style={{ color: "#b0b0b0", marginLeft: 6 }}> - {parseAndFormatDateTime(comment.data.uploaded_at)}</small> : ''}

                                                <br />
                                                <div style={{ marginTop: 4 }}>
                                                    {comment.comment.split("\\'").join("'")}
                                                    <small style={{ float: "right", color: "#b0b0b0" }}>{parseAndFormatDateTime(comment.created_at)}</small>
                                                    {this.canDeleteComment(comment) && <DeleteIcon onClick={() => this.setState({ comment_id_to_delete: comment.id })} style={{ color: "#5e6573", float: "right", marginRight: 8, border: "1px solid #ccc", borderRadius: "3px", padding: 1, cursor: "pointer" }} />}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {this.state.imageLoaded && <div style={{ position: "absolute", left: 20, right: 20, bottom: 20, borderTop: '1px solid #e0e0e0', paddingTop: 12 }}>
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item xs zeroMinWidth>
                                                <InputBase
                                                    value={commentInput}
                                                    onChange={(e) => this.setState({ commentInput: e.target.value })}
                                                    placeholder="Write a Comment..."
                                                    fullWidth
                                                    classes={{
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                {this.state.loading ? <CircularProgress size={"25px"} /> : <Fab onClick={() => this.addComment(commentInput)} color="primary" size="medium">
                                                    <ReplyRoundedIcon />
                                                </Fab>}
                                            </Grid>
                                        </Grid>
                                    </div>}
                                </Card>

                            </Grid>}
                        </Grid>
                    }

                </div>

                <ModalConfirmation open={isAddingComment === true}
                    title="Add Comment"
                    message='Enter a comment'
                    textInput={currentCommentInput}
                    onTextInputChange={(e) => { this.setState({ currentCommentInput: e.target.value }) }}
                    onClose={() => { this.setState({ isAddingComment: false, lastRectangle: null }); this.state.canvas.remove(this.state.lastRectangle); this.state.canvas.renderAll(); }}
                    onSubmit={() => { this.addComment(currentCommentInput) }} />

                <ModalConfirmation open={this.state.comment_id_to_delete != null}
                    title="Delete Comment"
                    message='Delete this comment?'
                    onClose={() => { this.setState({ comment_id_to_delete: null }); }}
                    onSubmit={() => { this.deleteComment() }} />

                {/* <script src="node_modules/exif-js/exif-js"></script> */}
            </React.Fragment>
        );
    }
}

PhotoView.defaultProps = {
    style: {},
}

export default withAuth(PhotoView);
